<template>
    <div class="father">
        <div class="header">
            <div class="header1">
                <el-image style="" :src="require('../assets/report.png')" fit="fit"></el-image>
            </div>
            <div class="header2"></div>
            <div class="header3" @click="join">
                <p style="color: #EFFFFF;text-align: center;font-weight: bold;">
                    <el-icon size="16" color="#EFFFFF">
                        <Search />
                    </el-icon>
                    更多查询
                </p>
            </div>
        </div>
        <div class="bottom1">
            <div class="wrapper2">
                <p style="text-align: center;font-size: 18px;color: #E6B36C;">无效的报告书编号!</p>

                <div>
                    <p style="text-align: center; color:#0000FF;">如需进一步核实请拨打专业工程师电话：</p>
                    <p style="text-align: center;color:#0000FF;margin-top: 0px;">电话号码为：400 6288 233</p>
                    <p style="text-align: center;color: #0000FF;">010-69479673</p>
                </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;">
                <el-button size="large" style="margin-top: 25px;" type="success" @click="goBack">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>

// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: "SuccessView",
    components: {
        HelloWorld,
    },
    data() {
        return {
            tableData: [{

            }],


       



        }

    },
    methods: {
        join() {
            console.log(11111)
            this.$router.push('/join')
        },
        
    }


  
};
</script>

<style>
.father {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.header {
    height: 8vh;
    width: 100vw;
    display: flex;
    flex-direction: row;

}

.middle {
    height: 5vh;
    width: 100vw;
    background-color: #F3F3F4;
    display: flex;
    align-items: center;
    border: solid 1px #DDDDDD;
}

.bottom1 {
    margin-top: 0px;
    height: 92vh;
    width: 100vw;
    background-color: #F3F3F4;
}

.header1 {
    width: 15vw;
}

.header2 {
    width: 75vw;
    background-color: #00A65A;
}

.header3 {
    width: 10vw;
    background-color: #00A65A;
}

.header3:hover {
    background-color: #009551;
}

.wrapper2 {
    height: 25vh;
    width: 100vw;
    margin-top: 0px;
    background-color: #FCF8E3;

    border: 1px solid #ccc
}
</style>