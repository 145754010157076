<template>
    <Header></Header>
    <div class="header">

    </div>
    <div class="middle">
        <p style="width: 100%; height: 10vh;line-height: 10vh; font-size: 25px;text-align: center;">公司简介</p>
        <div class="inner">
           <div class="left">
                <img src="@/assets/aboutus1.jpg">
           </div>
           <div class="right">
               <p>深圳迅测检测有限公司是一家专业的检测机构，成立于2015年,总部位于中国深圳。我们致力于为客户提供广泛的产品检测服务，涵盖电子产品、机械设备、食品安全、环境保护等多个领域。</p>
               <p>作为行业内的领先者，我们拥有一支经验丰富、技术过硬的专业团队和先进的检测设备。无论是产品的质量安全检测、性能评估还是合规性测试，我们都能够为客户提供高效准确的解决方案。</p>
               <p>在深圳迅测检测有限公司，我们始终以客户满意为己任，将客户的需求置于首位。我们严格按照国家和行业标准进行检测，确保客户能够获得最可靠的检测结果和服务体验。</p>
               <p>我们的使命是通过专业的检测服务，为客户提供信心保障，助力产品质量提升和市场竞争力强化。我们坚信，深圳迅测检测有限公司将成为您可信赖的检测合作伙伴，共同开创美好的未来。</p>
            </div>
        </div>
      
        

    </div>
    <div class="foot">
        <span>Copyright©2019 All Rights Reserved</span>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    name: "Foot",
    components: {
        Header
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped>
span {
    line-height: 10vh;
    color: #ffffff;
    display: flex;
    justify-content: center;
    
}
.header {
    width: 100%;
    height: 30vh;
    background-image: url("@/assets/aboutus.jpg");
    background-size: 100% 100%;
    /* background-size: auto; 
    background-position: center center;  */
}

.middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60vh;
}
.middle p {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    font-size: 17.2px;
    text-align: left;
}

.foot {
    width: 100%;
    height: 10vh;
    background-color: #333333;
}


.inner {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
}

span {
    font-size: 20px;
}
.left {
    
    width: 50%;
    height: 100%;
    
}
.left img {
    position: relative;
    right: -120px;
    width: 70%;
    height: 75%;
}
.right {
    width: 50%;
    height: 100%;
}


</style>