<template>
    <Header></Header>
    <div class="top">

    </div>
    <div class="middle">
        <p style="font-size: 25px;">检测服务</p>
        <div class="inner">
           <div>
                <img src="@/assets/wuxian.png">
                <p>5GeMBB终端产品:5G手机、5G模组、5G热点(MIFI)、5GCPE、5G设配器/dongles、USB可插拔5G终端、AR/VR/MR产品(眼镜、耳机、头盔)、自动驾驶设备(各类汽车)</p>
           </div>
           <div>
                <img src="@/assets/car.png">
                <p>电器性能测试、辐射抗干扰测试系统（含雷达波）、静电放电、瞬态传导抗干扰测试、便携式发射机抗扰、低频磁场抗干扰测试、瞬态传导发射测试系统、低频磁场发射</p>
           </div>
           <div>
                <img src="@/assets/dianzi.png">
                <p>家用电器产品检测、电器附件产品检测、家用电器检测</p>
           </div>
           <div>
                <img src="@/assets/dianchi.png">
                <p>动力储能电池检测认证、消费电子类电池检测认证、静电放电、动力电池、轻型电动车辆电池、储能电池、电池类检测、电池测试</p>
           </div>
        </div>
    

    </div>
    <div class="foot">
        <span>Copyright©2019 All Rights Reserved</span>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    name: "Foot",
    components : {
        Header
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped>
span {
    line-height: 10vh;
    color: #ffffff;
    display: flex;
    justify-content: center;
    
}
.top {
    width: 100%;
    height: 30vh;
    background-image: url("@/assets/foot.jpg");
    background-size: 100% 100%;
    /* background-size: auto; 
    background-position: center center;  */
}

.middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
   
}

.foot {
    width: 100%;
    height: 10vh;
    background-color: #333333;
}

p {
    width: 100%;
    text-align: center;

}

.inner {
    width: 100vw;
    height: 60%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    text-align: center;
    
}
.inner > div {
    margin-top: 15px;
    width: 45%;
    height: 50%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.inner > div > p {
    width: 70%;
    font-size: 17px;
    text-align: left;
    right: 0;
}

.botton {
    padding-top: 20px;
}
span {
    font-size: 20px;
}

img {
    width: 50px;
    height: 40px;
}

</style>