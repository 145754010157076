<template>
    <Header></Header>
    <div class="header">

    </div>
    <div class="middle">
        <p style="width: 100%; font-size: 25px;text-align: center;margin-top: 10px;">联系我们</p>
        <p style="font-size: 13px;text-align: center;margin-top: 0px;">如果有任何问题请联系我们，我们竭诫为您服务</p>
        <div class="inner">
          <div class="contain">
            <div class="head">
            <span style="font-weight: bold;font-size: 30px;">深圳迅测检测有限公司</span><br>
            <span style="font-size: 15px;">SHENZHEN XUNCHE TESTING CO.,LTD.</span>
          </div>

          <div class="bottom">
            <img src="@/assets/xinfeng.png" style="display: inline-block;" >
            <span style="padding-left: 20px;">Webconsult@szspcheck.com</span>
          </div>
        
          </div>
            
          
        </div>
      
        

    </div>
    <div class="foot">
        <span>Copyright©2019 All Rights Reserved</span>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    name: "Foot",
    components: {
        Header,
    },
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.foot span {
    line-height: 10vh;
    color: #ffffff;
    display: flex;
    justify-content: center;
    
}
.header {
    width: 100%;
    height: 30vh;
    background-image: url("@/assets/relative.jpg");
    /* background-size: 100% 100%; */
    background-size: cover; 
    background-position: center center; 
}

.middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 60vh;
    background-color: aquamarine;
    background-image: url("@/assets/relativeb.jpg");
    background-size: cover;
    background-position: center center;
}
.middle p {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    font-size: 17.2px;
    text-align: left;
}

.foot {
    width: 100%;
    height: 10vh;
    background-color: #333333;
}


.inner {
    margin-left: -10vw;
    width: 50%;
    height: 80%;
   
    
}
.contain {
    margin:15vh auto;
}


.left {
    
    width: 50%;
    height: 100%;
    
}

.right {
    width: 50%;
    height: 100%;
}
.bottom {
    margin-top: 5vh;
}

</style>