<template>
   <p>你好！！！</p>
</template>

<script>


export default {
    name: "JoinView",
    components: {
    },
    data() {
        return {

        }
        
    },
    methods: {
        join() {
           
        }
    }
};
</script>

<style>
</style>