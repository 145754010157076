<template>
    <div class="wrap">
        <div class="about">
            <div class="tupian">
                <img src="../assets/report.jpg">
            </div>
            <div class="wenzi">
                <ul>
                    <li><span><a href="/">首页</a></span></li>
                    <li><span><a href="/#/home">报告查询</a></span></li>
                    <li><span><a href="/#/about">关于我们</a></span></li>

                </ul>
            </div>
        </div>
    </div>
    <div class="middle"></div>

    <div class="a">
        <div class="inner">
            <h1>公司简介</h1>
            <div class="cont">
                深圳迅测检测有限公司致力于危险品运输相关领域，是危险品检验检测机构、危险品培训机构。
                <br>
                公司以尊重规则为理念，以保障危险品安全运输为宗旨。
            </div>
        </div>
    </div>

    <div class="bottom">
        <div class="left">
            <p class="adress">广州</p>
            <div class="tel">
                
                <el-icon :size="30"><PhoneFilled /></el-icon>
                <p>020-36798001/02(福联)</p>
            </div>
            <div class="location">
                <el-icon :size="30"><Location /></el-icon>
                <p>青岛市胶东机场盛港一路9号囯内货站出港营业厅迪捷姆公司(胶州)</p>
            </div>
            <div class="network">
                <el-icon :size="30"><Monitor /></el-icon>
                <p>qingdao@dgmchina.com.cn</p>
            </div>
        </div>
        <div class="center">
            <p class="adress">广州</p>
            <div class="tel">
                
                <el-icon :size="30"><PhoneFilled /></el-icon>
                <p>020-36798001/02(福联)</p>
            </div>
            <div class="location">
                <el-icon :size="30"><Location /></el-icon>
                <p>青岛市胶东机场盛港一路9号囯内货站出港营业厅迪捷姆公司(胶州)</p>
            </div>
            <div class="network">
                <el-icon :size="30"><Monitor /></el-icon>
                <p>qingdao@dgmchina.com.cn</p>
            </div>
        </div>
        <div class="right">
            <p class="adress">广州</p>
            <div class="tel">
                
                <el-icon :size="30"><PhoneFilled /></el-icon>
                <p>020-36798001/02(福联)</p>
            </div>
            <div class="location">
                <el-icon :size="30"><Location /></el-icon>
                <p>青岛市胶东机场盛港一路9号囯内货站出港营业厅迪捷姆公司(胶州)</p>
            </div>
            <div class="network">
                <el-icon :size="30"><Monitor /></el-icon>
                <p>qingdao@dgmchina.com.cn</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
<style scoped >
.tel,.location,.network {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: small;
    margin-top: 30px;
}


.adress {
    width: 100%;
    background: url(https://www.dgmchina.com.cn/images/btbj_24.png) no-repeat;
    
    color: #ffffff;
}

.left {

    width: 300px;
    height: 400px;
    background-color: #59AD25;
}

.center {
    width: 500px;
    height: 500px;
    background-color: #59AD25;
    margin-left: 12px;
    margin-right: 12px;
}

.right {
    width: 300px;
    height: 400px;
    background-color: #59AD25;
}

.bottom {
    width: 100vw;
    height: 550px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cont {
    padding: 3% 0;
    width: 90%;
    margin: 0 auto;
    font-size: 20px;
    line-height: 40px;
    color: #333333;
    text-align: center;
}

.a {
    margin-top: 10px;
    width: 100%;
    height: 300px;
}

.inner {
    margin-bottom: 5%;
}

h1 {
    text-align: center;
}

.wrap {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100px;
    background-color: #ffffff;

}

.about {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

}

.tupian {
    width: 50%;
    height: 150px;

}

.wenzi {
    width: 50%;
    height: 150px;

}


.wenzi ul {
    height: 10vh;
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.wenzi ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wenzi ul li span {
    font-size: 18px;

}

a {
    text-decoration: none;
    color: black;
}

a:hover {
    color: #1776C1
}

.middle {
    margin-top: 150px;
    width: 100vw;
    height: 150px;
    background-image: url("https://www.dgmchina.com.cn/uploadfile/2019/1105/20191105105921971.png");
    background-size: cover;
    background-position: center center;

}
</style>