<template>
    <div class="wrap">
        <div class="header">
            <div class="left">
                <img src="../assets/report.jpg">
            </div>
            <div class="right">
                <ul>
                    <li v-for="(item,index) in rulePath" :key="index" @click="jump(item.path)"><span>{{ item.name }}</span></li>
                    

                </ul>
            </div>
        </div>
        <div class="content">
            
        </div>
        <div class="middle">
            <div @click="wuxian">
                <img src="../assets/wuxian.png">
                <span>无线通讯</span>
            </div>
            <div @click="car">
                <img src="../assets/car.png">
                <span>汽车电子</span>
            </div>
            <div @click="dianzi">
                <img src="../assets/dianzi.png">
                <span>电子电器</span>
            </div>
            <div @click="dianchi">
                <img src="../assets/dianchi.png">
                <span>电池检测</span>
            </div>
        </div>
        <div class="bottom">
            <span>Copyright©2019 All Rights Reserved</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "index",
    data() {
        return {
            rulePath: [
            {
                   
                    path: '/',
                    name: '首页'
                   
                },
                {
                    
                    path: '/service',
                    name: '我们的服务',
                   
                },

                {
                    
                    path: '/home',
                    name: '报告查询',
                   
                },
                {
                    
                    path: '/relativeus',
                    name: '联系我们'
                   
                },
                {
                    
                    path: '/aboutus',
                    name: '关于我们'
                   
                },
                
            ],
        }
    },
    methods: {
        wuxian() {
            this.$router.push('/wuxian');
        },
        car() {
            this.$router.push('/car'); 
        },
        dianzi() {
            this.$router.push('/dianzi'); 
        },
        dianchi() {
            this.$router.push('/dianchi');
        },
        jump(path) {
            if(this.$route.path == path) {
                return
            }

            this.$router.push(path);
        }
    }
}
</script>

<style scoped>
.middle {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #ffffff;
}
.middle img {
    width: 55px;
    height: auto;
}
.middle div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.middle span {
    display: block;
    margin-top: 15px;
    font-size: 30px;
}
.bottom {
    width: 100%;
    height: 10vh;
    background-color: #333333;
    position: relative;
}
.bottom span {
    line-height: 9vh;
    color: #ffffff;
    display: flex;
    justify-content: center;
    
}
.wrap {
    margin: 0;
    padding: 0;
    height: 100vh;
    overflow: hidden;
}

.header {
    display: flex;
    width: 100vw;
    height: 15vh;
    z-index: 999;
    top: 0px;
    left: 0px;
}

.left {
    width: 53%;
    height: 100%;

}

.right {
    width: 55%;
    height: 100%;
    margin-top: 18px;
}

.right ul {
    height: 3vh;
    width: 87%;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.right ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right ul li span {
    font-size: 20px;

}

a {
    text-decoration: none;
    color: black;
}

li:hover {
    color: #1776C1
}

.content {
    height: 55vh;
    width: 158%;
    background-image: url("../assets/index.jpg");
    background-size: auto; 
    background-position: center center; 
   
}
.middle div:hover {
    color: green;
}
</style>