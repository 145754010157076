<template>
    <div class="headerC">
    <div class="leftC">
        <img src="../assets/report.jpg">
    </div>
    <div class="rightC">
        <ul>
            <li v-for="(item,index) in rulePath" :key="index" @click="jump(item.path)"><span>{{ item.name }}</span></li>

        </ul>
    </div>
</div>

</template>
<script>
export default {
    data() {
        return {
            rulePath: [
            {
                   
                    path: '/',
                    name: '首页'
                   
                },
                {
                    
                    path: '/service',
                    name: '我们的服务',
                   
                },

                {
                    
                    path: '/home',
                    name: '报告查询',
                   
                },
                {
                    
                    path: '/relativeus',
                    name: '联系我们'
                   
                },
                {
                    
                    path: '/aboutus',
                    name: '关于我们'
                   
                },
                
            ],
        }
    },
    methods: {
        jump(path) {
            if(this.$route.path == path) {
                return
            }

            this.$router.push(path);
        }
    }
}
</script>
<style scoped>
.headerC {
    display: flex;
    width: 100vw;
    height: 15vh;
    z-index: 999;
    top: 0px;
    left: 0px;
}

.leftC {
    width: 53%;
    height: 100%;

}

.rightC {
    width: 55%;
    height: 100%;
    margin-top: 18px;
}

.rightC ul {
    height: 3vh;
    width: 87%;
    list-style-type: none;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.rightC ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rightC ul li span {
    font-size: 20px;

}

a {
    text-decoration: none;
    color: black;
}

li:hover {
    color: #1776C1
}

</style>