<template>
    <Header></Header>
    <div class="header">

    </div>
    <div class="middle">
        <p style="font-size: 25px;">电子电器</p>
        <div class="inner">
            <p style="text-align: left;">吸尘器类、电慰斗类、嵌入式烤箱、烤炉类、剃须刀、电推剪类、便携式面包机、烤箱、烤炉类、地板处理机、温式痹洗机类、保温板|盘类、油炸锅类、厨房电动食材加工处理机类、水壶、咖啡机类、直发器、卷发器、吹风机、千手机类、电冰箱、制冰机类、光辐射皮肤器具类、电池充电器类、加热器、取暖器类、吸油烟机类、足浴盆、按摩器类、空调、除湿机类干衣机、毛巾架类电络铁、热烘枪类、商用保温柜类、电动牙别、冲牙器类、使用液体的表面清洁器具类、电击杀灭虫器类、空气净化器类、固定浸入式加热器类、便携漫入式加热器类、商用售卖机类、风家类、暖脚器和热脚热类、服务和娱乐器具类、马桶类、织物燕汽机类、车库门驱动装置类、门、窗驱动装置类、加湿器类、香薰器、电热香器类、电燃气灶类、脱毛仪类滑板车、平衡车类</p>
        </div>
        <div class="botton">
            <el-button @click="jump" style="background-color: #482AA6;color: #ffffff;width: 100px;height: 50px;"><span>立即咨询</span></el-button>
            <el-button @click="jump" style="background-color: #000000;color: #ffffff;width: 100px;height: 50px;"><span>获取报价</span></el-button>
        </div>

    </div>
    <div class="foot">
        <span>Copyright©2019 All Rights Reserved</span>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    name: "Foot",
    components: {
        Header
    },
    data() {
        return {

        }
    },
    methods: {
        jump() {
            this.$router.push('/relativeus'); 
        }
    }
}
</script>
<style scoped>
span {
    line-height: 10vh;
    color: #ffffff;
    display: flex;
    justify-content: center;
    
}
.header {
    width: 100%;
    height: 30vh;
    background-image: url("@/assets/foot1.jpg");
    background-size: 100% 100%;
    /* background-size: auto; 
    background-position: center center;  */
}

.middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    background-color: antiquewhite;
}

.foot {
    width: 100%;
    height: 10vh;
    background-color: #333333;
}

p {
    width: 100%;
    text-align: center;

}

.inner {
    width: 60%;
    height: 60%;
    text-align: center;
}
.botton {
    padding-top: 20px;
}
span {
    font-size: 20px;
}
</style>