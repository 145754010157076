<template>
    <router-view />
</template>

<style>
p {
  font-size: 20px;
}
 html,
  body {
    margin: 0;
    padding: 0;
    height: 100vh;
  }
  #app {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100vh;
    width: 100vw;
    
  } 
  html{font-size:70.5%(10/16*100%)}


</style>
