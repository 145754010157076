import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import JoinVie from "../views/JoinView.vue";
import SuceessView from "../views/SuccessView.vue";
import A from "../views/A.vue";
import index from "../views/index.vue"
import About from "@/views/About.vue";
import Dianzi from "@/views/Dianzi.vue";
import Car from "@/views/Car.vue";
import Service from "@/views/Service.vue"  
import Dianchi from "@/views/Dianchi.vue"
import Wuxian from "@/views/Wuxian.vue"
import Aboutus from "@/views/Aboutus.vue"
import Relativeus from "../views/Relativeus.vue";




const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },

  {
    path: "/join",
    name: "join",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
   component: JoinVie,
  },

  {
    path: "/suceess",
    name: "sucesss",
    component: SuceessView,

  },

  {
    path: "/a",
    name: "a",
    component: A,
  },

  {
    path: "/about",
    name: "about",
    component: About,
  },

  
  {
    path: "/dianzi",
    name: "dianzi",
    component: Dianzi,
  },

  {
    path: "/car",
    name: "car",
    component: Car,
  },

  {
    path: "/service",
    name: "sercice",
    component: Service,
  },
  {
    path: "/dianchi",
    name: "dianchi",
    component: Dianchi,
  },

  {
    path: "/wuxian",
    name: "wuxian",
    component: Wuxian,
  },

  {
    path: "/aboutus",
    name: "aboutus",
    component: Aboutus,
  },
 
  {
    path: "/relativeus",
    name: "relative",
    component: Relativeus,
  },
 
  

   
 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
