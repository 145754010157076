<template>
    <div class="wrap">
        <div class="header">
           <p>{{ tableData.itemEnglishName }}Test Report</p>
           <p>{{ tableData.itemName }}测试报告</p>
           <p style="font-weight: normal;font-size: 15px;">{{ tableData.standard }}</p>
        </div>
        <div class="main">
          <!--  <p>Sample name: <span>{{ tableData.itemEnglishName }}</span></p>
            <p>物品名称： {{ tableData.itemName }}</p>
            <p>Model: {{ tableData.modelEnglish }}</p>
            <p>型号: {{ tableData.model }}</p>
            <p>Applicant: {{ tableData.clientEnglish }}</p>
            <p>申请商: {{ tableData.client }}</p>  -->
            <table class="table" style="font-size: 20px;">
                <tr>
                    <th>Sample name:</th>
                    <td>{{ tableData.itemEnglishName }}</td>
                </tr>
                <tr>
                    <th>物品名称:</th>
                    <td>{{ tableData.itemName }}</td>
                </tr>
                
                <tr>
                    <th>Model:</th>
                    <td>{{ tableData.modelEnglish }}</td>
                </tr>

                <tr>
                    <th>型号:</th>
                    <td>{{ tableData.model }}</td>
                </tr>
                
                <tr>
                    <th>Applicant:</th>
                    <td>{{ tableData.clientEnglish }}</td>
                </tr>

                <tr>
                    <th>申请商:</th>
                    <td>{{ tableData.client }}</td>
                </tr>

            </table>
            <div class="buttom">
                <el-button size="large" style="margin-top: 25px;font-size: 20px;" type="success" @click="goBack">返回</el-button>
        </div>
        </div>
       
    </div>
</template>

<script>
export default {
    name: 'A',
    data() {
        return {
            
            tableData: 
                {
                    itemEnglishName: this.$route.query.itemEnglishName,
                    itemName: this.$route.query.itemName,
                    modelEnglish: this.$route.query.modelEnglish,
                    model: this.$route.query.model,
                    clientEnglish: this.$route.query.clientEnglish,
                    client: this.$route.query.client,
                    standard: this.$route.query.standard
                }
            
        }
    },
    methods : {
        goBack() {
            this.tableData = {}
            this.$router.replace('/')
        },

    }
}
</script>
<style scoped>
p {
    font-weight: bold;
    font-size: 20px;
    word-break: break-all;
    word-wrap: break-word;
}
.wrap {
    width: 100%;
    height: 100vh;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.header {
    width: 450px;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}
.main {
    width: 50%;
    height: 70vh;
    margin-top: 20px;
    
}
span {
    word-break: normal;
    
}
.table {
    table-layout: fixed;
    width: 100%;
}
.table td {
    word-wrap:break-word;
}
.buttom {
    font-size: 30px;
    width: 20%;
    height: 5vh;
    margin-left: 50%;
    margin-top: 10px;
}


</style>