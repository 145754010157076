<template>
    <Header></Header>
    <div class="header">

    </div>
    <div class="middle">
        <p style="font-size: 25px;">汽车电子</p>
        <div class="inner">
            <p style="text-align: left;">各类车用电子部件包括车载摄像头、空调控制器、导航系统、组合灯、氛围灯、阅读灯、4GTbOx、5GTbox、仪表、车窗控制开关、车窗电机、电子后视镜、驾驶角度传感器、车用无线电、暖通空调、防盗中控系统、控制器、显示器、照明、底盘位置传感器和控制器、轮胎压力传感器、倒车提醒装置、ACP、VCP、CCH功放、全球定位装置、直流马达(用于天窗、座椅、车门、车窗、雨刮器)。在EMC测试项目中，涉及辐射发射、传导发射、传导瞬态发射、辐射抗扰度测试(包括电波暗室试验法)、大电流注)测试(代替法和闭环法)、传导瞬态抗扰度测试(包括电源线、信号线以及电感耦合钳试验法、直接电容耦合试验法和电容耦合钳试验法)、磁场抗扰度测试、静电放电测试。</p>
        </div>
        <div class="botton">
            <el-button @click="jump" style="background-color: #482AA6;color: #ffffff;width: 100px;height: 50px;"><span>立即咨询</span></el-button>
            <el-button @click="jump" style="background-color: #000000;color: #ffffff;width: 100px;height: 50px;"><span>获取报价</span></el-button>
        </div>

    </div>
    <div class="foot">
        <span>Copyright©2019 All Rights Reserved</span>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
export default {
    components: {
        Header
    },
    name: "Foot",
    data() {
        return {

        }
    },
    methods: {
        jump() {
            this.$router.push('/relativeus'); 
        }
    }
}
</script>
<style scoped>
span {
    line-height: 10vh;
    color: #ffffff;
    display: flex;
    justify-content: center;
    
}
.header {
    width: 100%;
    height: 30vh;
    background-image: url("@/assets/foot1.jpg");
    background-size: 100% 100%;
    /* background-size: auto; 
    background-position: center center;  */
}

.middle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60vh;
    background-color: antiquewhite;
}

.foot {
    width: 100%;
    height: 10vh;
    background-color: #333333;
}

p {
    width: 100%;
    text-align: center;

}

.inner {
    width: 60%;
    height: 60%;
    text-align: center;
}
.botton {
    padding-top: 20px;
}
span {
    font-size: 20px;
}
</style>