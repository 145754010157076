<template>
    <div class="father">
        
        <!-- <div class="header">
            <div class="header1">
                <el-image style="" :src="require('../assets/report.jpg')" fit="fit"></el-image>
            </div>
            <div class="header2"></div>
            <div class="header3" >
                <p style="color: #EFFFFF;text-align: center;font-weight: bold;">
                    <el-icon size="16" color="#EFFFFF">
                        <Search />
                    </el-icon>
                    更多查询
                </p>
            </div>
        </div> -->
        <Header></Header>
        <div class="middle" v-if="code !== 404">
            <p style="margin-left: 10px;color: #638CCF;">您可以查询报告时间段为本年度，请扫描条形码或输入报告书编号进行查询</p>
        </div>
        <div class="bottom1">
            <template v-if="code === 333">
                <div class="wrapper">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                        class="demo-ruleForm" size="mini">
                        <el-form-item label="扫描条形码" prop="pass">
                            <el-input v-model="ruleForm.pass" autocomplete="off" style="width: 25vw;"
                                placeholder="扫描条形码查询"></el-input>
                        </el-form-item>
                        <el-form-item label="报告书编号" prop="checkPass">
                            <el-input v-model="ruleForm.reportId" autocomplete="off" style="width: 25vw;"
                                placeholder="输入报告书编号查询"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="large" color="#1AB394" @click="getReport">
                                <p style="color: #FFFFFF;">查询</p>
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </template>

            <template v-if="code === 200">
                <div class="bottom1">
                    <div class="wrapper1">
                        <p>报告书信息</p>
                        <el-table :data="getValues" :show-header="false" width="200">
                            <el-table-column v-for="(item, index) in getHeaders" :key="index" :prop="item" width="300">
                            </el-table-column>
                        </el-table>

                        <el-button size="large" style="margin-top: 25px;" type="success" @click="goBack">返回</el-button>

                    </div>


                </div>
            </template>

            <template v-if="code === 404">
                <div class="wrapper2">
                <p style="text-align: center;font-size: 18px;color: #E6B36C;">无效的报告书编号!</p>

                <div>
                    <p style="text-align: center; color:#0000FF;">如需进一步核实请拨打专业工程师电话：</p>
                    <p style="text-align: center;color:#0000FF;margin-top: 0px;">电话号码为：400 6288 233</p>
                    <p style="text-align: center;color: #0000FF;">010-69479673</p>
                </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;">
                <el-button size="large" style="margin-top: 25px;" type="success" @click="goBack">返回</el-button>
            </div>
            </template>



        </div>
    </div>
</template>

<script>

// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import Header from '@/components/Header.vue'
export default {
    name: "HomeView",
    components: {
        HelloWorld,
        Header
    },
    data() {
        return {
            code: 333,
            ruleForm: {
                reportId: '',
            },
            rules: {
                pass: [
                    { trigger: 'blur' }
                ],
                checkPass: [
                    { trigger: 'blur' }
                ],
                age: [
                    { trigger: 'blur' }
                ]
            },
            tableData: [{

            }],


            headers: [
             /*    {
                    prop: 'reportId',
                    label: '报告书编号',
                }, */
                {
                    prop: 'itemEnglishName',
                    label: 'Sample name'
                },

                {
                    prop: 'itemName',
                    label: '物品名称',
                },
                {
                    prop: 'modelEnglish',
                    label: 'Model',
                },

                {
                    prop: 'model',
                    label: '型号',
                },

              
                {
                    prop: 'clientEnglish',
                    label: 'Applicant',
                },
                {
                    prop: 'client',
                    label: '申请商',
                },
              
              
              /*   {
                    prop: 'effectiveDate',
                    label: '生效日期',
                },
               */
              /*   {
                    prop: 'manufacturer',
                    label: '生产厂家',
                },
                {
                    prop: 'manufacturerEnglish',
                    label: '生产厂家',

                },
                {
                    prop: 'result',
                    label: '检查结果',
                } */

            ],
            tableData: [

            ]
        }

    },
    methods: {
        join() {
            console.log(11111)
            this.$router.push('/join')
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 1 && columnIndex === 0) {
                return [2, 1]
            } else if (rowIndex === 2 && columnIndex === 0) {
                return [0, 0]
            } else if (rowIndex === 4 && columnIndex === 0) {
                return [2, 1]
            } else if (rowIndex === 5 && columnIndex === 0) {
                return [0, 0]
            } else if (rowIndex === 6 && columnIndex === 0) {
                return [2, 1]
            } else if (rowIndex === 7 && columnIndex === 0) {
                return [0, 0]
            }
        },
        submitForm(ruleForm) {
            console.log(this.ruleForm.reportId)
            this.$refs[ruleForm].validate((valid) => {
                if (valid) {
                    alert('submit!');
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        goBack() {
            this.tableData = []
            this.code = 333
            this.$router.push({name:'home'}) 
            
        },

        getReport() {
            console.log(11111)
            let reportId = this.ruleForm.reportId
            console.log(reportId)
            this.$http.get('/report/getReport/',{params:{reportId}}).then(res => {
                console.log(111111,res.data.data);
                this.tableData = res.data.data
                console.log(222222,this.tableData)
                if(res.data.code === 200) {
                    //this.code = 200
                    //this.$router.push('/a',params:res.data.data)
                    this.$router.push({name:'a',query: res.data.data})
                    //this.ruleForm = {}
                }else if(res.data.code === 404) {
                    this.code = 404
                    this.ruleForm = {}
                }
            }).catch((err => {
                this.$message.error('服务器出错,请联系管理员');
                console.log(err)
            })
            );
        },

    },
    computed: {

        getHeaders() {
            return this.tableData.reduce((pre, cur, index) => pre.concat(`value${index}`), ['title'])
        },
        getValues() {
            return this.headers.map(item => {
                return this.tableData.reduce((pre, cur, index) => Object.assign(pre, { ['value' + index]: cur[item.prop] }), { 'title': item.label, });
            });
        }


    }
};
</script>

<style scoped>
.father {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.header {
    height: 8vh;
    width: 100%;
    display: flex;
    flex-direction: row;

}

.middle {
    margin-top: 10px;
    height: 5vh;
    width: 100%;
    background-color: #F3F3F4;
    display: flex;
    align-items: center;
    border: none;
}

.bottom1 {
    height: 70vh;
    width: 100%;
    background-color: #F3F3F4;
}

.header1 {
    width: 20px;
}

.header2 {
    width: 100px;
    background-color: #00A65A;
}

.header3 {
    width: 100px;
    background-color: #00A65A;
}

.header3:hover {
    background-color: #009551;
}

.wrapper {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

.wrapper1 {
    width: 200px;
    height: 45vh;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 40%;
    left: 50%;
    /* border: 1px solid #ccc */
}

::v-deep .el-table {
    border: none;
    
}

</style>